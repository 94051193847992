import { useQuery, useQueryClient } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
import { useEffect, useState } from "react";
export const useAddressSuggestions = (payload, options) => {
    const queryClient = useQueryClient();
    const { tenant, environment, restUrl } = extendURLParams();
    const [success, setSuccess] = useState(false);
    const { data: citiesResponse, refetch: refetchCities } = useQuery(["getCitiesForZipCode", tenant, environment], () => fetch(`${restUrl}/v1/address/cities/${payload.zip}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        if (res.ok) {
            setSuccess(true);
        }
        return res.json();
    })
        .catch((error) => new Error(error)), {
        cacheTime: 5 * 60 * 1000,
        enabled: Boolean(payload.zip) && payload.zip !== "",
        refetchOnWindowFocus: false,
        onSettled: () => queryClient.invalidateQueries(["getStreetsForZipCodeAndCity"]),
        ...options,
    });
    const { data: streetsResponse, refetch: refetchStreets } = useQuery(["getStreetsForZipCodeAndCity", tenant, environment], () => fetch(`${restUrl}/v1/address/streets/${payload.zip}/${payload.city}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        if (res.ok) {
            setSuccess(true);
        }
        return res.json();
    })
        .catch((error) => new Error(error)), {
        cacheTime: 5 * 60 * 1000,
        enabled: Boolean(payload.zip) && Boolean(payload.city),
        refetchOnWindowFocus: false,
        ...options,
    });
    useEffect(() => {
        queryClient.invalidateQueries(["getCitiesForZipCode"]);
    }, [payload.zip]);
    useEffect(() => {
        queryClient.invalidateQueries(["getStreetsForZipCodeAndCity"]);
    }, [payload.city]);
    const cities = citiesResponse?.payload ?? [];
    const streets = streetsResponse?.payload ?? [];
    return {
        clear: () => {
            queryClient.invalidateQueries([
                "getCitiesForZipCode",
                "getStreetsForZipCodeAndCity",
            ]);
        },
        data: {
            zip: payload.zip,
            cities,
            streets,
        },
        refetch: () => {
            queryClient.invalidateQueries([
                "getCitiesForZipCode",
                "getStreetsForZipCodeAndCity",
            ]);
            if (payload.zip) {
                refetchCities().then((response) => {
                    if (payload.city || response?.data?.payload?.length === 1) {
                        refetchStreets();
                    }
                });
            }
        },
        success,
    };
};
