import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { z } from "zod";
import { addDays, formatISODuration, intervalToDuration, isFuture, isValid, isWithinInterval, parse, startOfDay, } from "date-fns";
import { ConnectionType } from "@/types/ConnectionType";
import { CheckoutSectionText } from "../../components";
import { BuildingType, getInstallationTimeWindowLabel, InstallationTimeWindow, ItemisedBilling, PhoneNumberAmount, } from "../../types";
const shippingAddressSection = () => z.union([
    z.object({
        differingShippingAddress: z.literal(true, {
            required_error: "Bitte triff eine Auswahl.",
        }),
        shippingAddressSalutation: z.string({
            required_error: "Bitte gib eine Anrede an.",
        }),
        shippingAddressFirstName: z
            .string({
            required_error: "Bitte gib deinen Vornamen an.",
        })
            .trim()
            .min(1, { message: "Bitte gib deinen Vornamen ein." }),
        shippingAddressLastName: z
            .string({
            required_error: "Bitte gib deinen Nachnamen an.",
        })
            .trim()
            .min(1, { message: "Bitte gib deinen Nachnamen ein." }),
        shippingAddressZipCode: z
            .string({
            required_error: "Bitte gib deine Postleitzahl ein.",
        })
            .trim()
            .nonempty("Bitte gib deine Postleitzahl ein.")
            .length(5, "Deine Postleitzahl muss aus 5 Ziffern bestehen.")
            .regex(/^[0-9]+$/, "Deine Postleitzahl muss aus Zahlen bestehen."),
        shippingAddressPlace: z
            .string({
            required_error: "Bitte wähle deinen Wohnort aus.",
        })
            .trim()
            .nonempty("Bitte wähle deinen Wohnort aus."),
        shippingAddressStreet: z
            .string({
            required_error: "Bitte gib deinen Straßennamen ein.",
        })
            .trim()
            .nonempty("Bitte gib deinen Straßennamen ein.")
            .max(50, "Dein Straßenname darf maximal 50 Zeichen lang sein."),
        shippingAddressStreetNumber: z
            .string({
            required_error: "Bitte gib deine Hausnummer ein.",
        })
            .trim()
            .nonempty("Bitte gib deine Hausnummer ein.")
            .regex(/^[1-9][0-9]*[a-zA-Z]?(\s*[/-]?\s*[1-9][0-9]*[a-zA-Z]?)?$/, "Bitte gib eine gültige Hausnummer ein.")
            .max(10, "Deine Hausnummer darf maximal 10 Zeichen lang sein."),
    }),
    z.object({
        differingShippingAddress: z.literal(false, {
            required_error: "Bitte triff eine Auswahl.",
        }),
    }),
]);
/**
 * Configuration object for Checkout
 *
 * `key` determines the given Service Provider.
 * Please see `readme.md` for advice on how to use this!
 *
 * **Any changes made here should also be reflected in `checkout-states.md` for easier communication about endpoints!**
 *
 */
export const config = [
    {
        key: "vodafone",
        steps: [
            {
                type: "connection",
                sections: [
                    {
                        title: "Dein Anschluss",
                        sections: [
                            {
                                component: "connectionAddressSection",
                            },
                            {
                                component: "numberPortabilityInfoSection",
                            },
                            {
                                component: "existingCustomerToggleSection",
                                defaultValues: {
                                    existingCustomer: false,
                                },
                                schema: z.object({
                                    existingCustomer: z.boolean({
                                        required_error: "Bitte triff eine Auswahl.",
                                    }),
                                }),
                            },
                            {
                                // DSL
                                component: "vodafone.activationDateSection",
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                defaultValues: {
                                    activationDate: "",
                                    activationDateSelection: false,
                                },
                                options: {
                                    selectableStartInDays: 22,
                                    selectableEndInDays: 180,
                                    selectionTooltip: `Bei Auswahl von “schnellstmöglich” wird dein Anschluss ungefähr 21 bis 25
        Tage nach deiner Bestellung bereitstehen. Du kannst bei der Wahl “zu
        einem bestimmten Zeitpunkt” einen Tag zwischen 22 und 180 Tagen in der
        Zukunft auswählen.`,
                                },
                                schema: z.union([
                                    z.object({
                                        activationDateSelection: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        activationDate: z
                                            .string({
                                            required_error: "Bitte gib ein Lieferdatum an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib ein Lieferdatum an.")
                                            .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                            .refine((value) => {
                                            try {
                                                const date = parse(value, "dd.MM.yyyy", new Date());
                                                return isWithinInterval(date, {
                                                    start: startOfDay(addDays(new Date(), 22)),
                                                    end: startOfDay(addDays(new Date(), 180)),
                                                });
                                            }
                                            catch (e) {
                                                // eslint-disable-next-line no-console
                                                console.error(e);
                                                return false;
                                            }
                                        }, {
                                            message: "Bitte gib ein Datum zwischen 22 und 180 Tagen in der Zukunft an.",
                                        }),
                                    }),
                                    z.object({
                                        activationDateSelection: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                // Cable
                                component: "vodafone.activationDateSection",
                                hide: (options) => options.connectionType !== ConnectionType.cable,
                                defaultValues: {
                                    activationDate: "",
                                    activationDateSelection: false,
                                    // no selectionTooltip, since we use the fallback value here
                                },
                                options: {
                                    selectableStartInDays: 10,
                                    selectableEndInDays: 180,
                                    selectionTooltip: `Bei Auswahl von “schnellstmöglich” wird dein Anschluss ungefähr 5 bis 7
        Tage nach deiner Bestellung bereitstehen. Du kannst bei der Wahl “zu
        einem bestimmten Zeitpunkt” einen Tag zwischen 10 und 180 Tagen in der
        Zukunft auswählen.`,
                                },
                                schema: z.union([
                                    z.object({
                                        activationDateSelection: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        activationDate: z
                                            .string({
                                            required_error: "Bitte gib ein Lieferdatum an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib ein Lieferdatum an.")
                                            .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                            .refine((value) => {
                                            try {
                                                const date = parse(value, "dd.MM.yyyy", new Date());
                                                return isWithinInterval(date, {
                                                    start: startOfDay(addDays(new Date(), 10)),
                                                    end: startOfDay(addDays(new Date(), 180)),
                                                });
                                            }
                                            catch (e) {
                                                // eslint-disable-next-line no-console
                                                console.error(e);
                                                return false;
                                            }
                                        }, {
                                            message: "Bitte gib ein Datum zwischen 10 und 180 Tagen in der Zukunft an.",
                                        }),
                                    }),
                                    z.object({
                                        activationDateSelection: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contactAndPayment",
                sections: [
                    {
                        title: "Kontakt",
                        sections: [
                            {
                                component: "contactAddressSection",
                                defaultValues: {
                                    contactAddressSalutation: "Frau",
                                },
                                schema: z.object({
                                    contactAddressSalutation: z.enum(["Herr", "Frau"], {
                                        required_error: "Bitte wähle eine Anrede aus.",
                                    }),
                                    contactAddressFirstName: z
                                        .string({
                                        required_error: "Bitte gib deinen Vornamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Vornamen ein."),
                                    contactAddressLastName: z
                                        .string({
                                        required_error: "Bitte gib deinen Nachnamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Nachnamen ein."),
                                    contactAddressZipCode: z
                                        .string({
                                        required_error: "Bitte gib deine Postleitzahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Postleitzahl ein.")
                                        .length(5, "Deine Postleitzahl muss aus 5 Ziffern bestehen.")
                                        .regex(/^[0-9]+$/, "Deine Postleitzahl muss aus Zahlen bestehen."),
                                    contactAddressPlace: z
                                        .string({
                                        required_error: "Bitte wähle deinen Wohnort aus.",
                                    })
                                        .trim()
                                        .nonempty("Bitte wähle deinen Wohnort aus."),
                                    contactAddressStreet: z
                                        .string({
                                        required_error: "Bitte gib deinen Straßennamen ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Straßennamen ein.")
                                        .max(50, "Dein Straßenname darf maximal 50 Zeichen lang sein."),
                                    contactAddressStreetNumber: z
                                        .string({
                                        required_error: "Bitte gib deine Hausnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Hausnummer ein."),
                                    contactAddressBirthDay: z
                                        .string({
                                        required_error: "Bitte gib dein Geburtsdatum ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib dein Geburtsdatum ein.")
                                        .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                        .refine((value) => {
                                        const startDate = parse(value, "dd.MM.yyyy", new Date());
                                        return isValid(startDate);
                                    }, {
                                        message: "Bitte gib ein gültiges Datum ein.",
                                    })
                                        .refine((value) => {
                                        try {
                                            const startDate = parse(value, "dd.MM.yyyy", new Date());
                                            if (!isValid(startDate) || isFuture(startDate))
                                                return false;
                                            const age = formatISODuration(intervalToDuration({
                                                start: parse(value, "dd.MM.yyyy", new Date()),
                                                end: new Date(),
                                            })).match(/^P(\d+)Y/)?.[1];
                                            return Number(age) >= 18;
                                        }
                                        catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error(e);
                                            return false;
                                        }
                                    }, {
                                        message: "Das Mindestalter zum Abschluss des Vertrages beträgt 18 Jahre.",
                                    }),
                                    contactAddressEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressConfirmEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse erneut ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse erneut ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressPhoneNumberPrefix: z
                                        .string({
                                        required_error: "Bitte gib eine Vorwahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Vorwahl ein.")
                                        .regex(/^0[1-9][0-9]{1,4}$/, "Bitte gib eine gültige Vorwahl ein."),
                                    contactAddressPhoneNumberExtension: z
                                        .string({
                                        required_error: "Bitte gib eine Rufnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Rufnummer ein.")
                                        .max(8, "Bitte gib maximal 8 Zeichen ein.")
                                        .regex(/^[0-9]{3,}$/, "Bitte gib eine Rufnummer ein."),
                                }),
                            },
                            {
                                component: "shippingAddressSection",
                                schema: shippingAddressSection(),
                                options: {
                                    enableAddressSuggestions: true,
                                },
                            },
                        ],
                    },
                    {
                        title: "Bezahlung",
                        sections: [
                            {
                                defaultValues: {
                                    sepaAccepted: false,
                                },
                                component: "bankAccountSection",
                                options: {
                                    label: "Ich ermächtige die Vodafone Deutschland GmbH (Gläubiger ID: DE20KD000000058294), Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von dem Provider von meinem Konto geforderten Lastschriften einzulösen.",
                                    content: (_jsx(CheckoutSectionText, { children: "Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen." })),
                                },
                                schema: z.object({
                                    sepaAccepted: z.boolean().refine((value) => value, {
                                        message: "Bitte stimme dem SEPA-Mandat zu",
                                    }),
                                    iban: z
                                        .string({
                                        required_error: "Bitte gib deine IBAN ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine IBAN ein."),
                                }),
                            },
                            {
                                component: "promotionCodeSection",
                                hide: (options) => Boolean(options.onlineOnly),
                                defaultValues: {
                                    promotionCode: "",
                                },
                                schema: z.object({
                                    promotionCode: z.string().trim().optional(),
                                }),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contractSummary",
                sections: [
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "vodafone.contractSummarySection",
                                defaultValues: {
                                    eeccDocumentsDownloaded: false,
                                    eeccDocumentMaxRetriesReached: false,
                                },
                                schema: z.union([
                                    z.object({
                                        eeccDocumentsDownloaded: z
                                            .boolean()
                                            .refine((value) => value, {
                                            message: "Bitte lade erst deine Vertragsdokumente herunter.",
                                        }),
                                        eeccDocumentMaxRetriesReached: z
                                            .literal(false)
                                            .optional(),
                                    }),
                                    z.object({
                                        eeccDocumentsDownloaded: z
                                            .literal(false)
                                            .optional(),
                                        eeccDocumentMaxRetriesReached: z.literal(true),
                                    }),
                                ]),
                            },
                        ],
                    },
                ],
            },
            {
                type: "orderSummary",
                legalNote: (pibUrl, openAGBModal, openPrivacyModal) => (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "https://www.vodafone.de/agb.html", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen der Vodafone" }), ", sowie die", " ", _jsx("a", { href: "https://content.ekontor24.net/mediamarkt/pdf/datenschutzmerkblatt-vodafone.pdf", target: "_blank", rel: "noreferrer", children: "Informationen zum Datenschutz" }), " ", "zur Kenntnis genommen habe und mit ihnen einverstanden bin."] }), _jsxs("p", { children: ["Weitere Informationen finde ich hier im", " ", _jsx("a", { href: pibUrl, target: "_blank", rel: "noreferrer", children: "Produktinformationsblatt" }), "."] }), _jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "/agb/", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen" }), " ", "der S-KON eKontor 24 GmbH sowie die", " ", _jsx("a", { href: "/datenschutz/", target: "_blank", rel: "noreferrer", children: "Informationen zum Datenschutz" }), " ", "der S-KON eKontor 24 GmbH zur Kenntnis genommen habe und mit ihnen einverstanden bin."] })] })),
                submitLabel: "Kostenpflichtig bestellen",
                sections: [
                    {
                        title: "Anschluss",
                        sections: [
                            {
                                component: "vodafone.connectionOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Kontakt & Bezahlung",
                        sections: [
                            {
                                component: "vodafone.contactOverviewSection",
                            },
                            {
                                component: "vodafone.paymentOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "vodafone.contractOverviewSection",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        key: "o2",
        steps: [
            {
                type: "connection",
                sections: [
                    {
                        title: "Dein Anschluss",
                        sections: [
                            {
                                component: "connectionAddressSection",
                            },
                            {
                                component: "o2.phoneNumberAmountSection",
                                defaultValues: {},
                                // only show for o2 dsl
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                schema: z.object({
                                    phoneNumberAmount: z.nativeEnum(PhoneNumberAmount, {
                                        errorMap: (issue, ctx) => {
                                            if (issue.code === "invalid_enum_value") {
                                                return {
                                                    message: "Bitte gib an, wie viele Rufnummern du benötigst.",
                                                };
                                            }
                                            return { message: issue.message ?? " " };
                                        },
                                    }),
                                }),
                            },
                            {
                                component: "o2.buildingTypeSection",
                                defaultValues: {
                                    buildingType: "",
                                },
                                // only show for o2 dsl
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                schema: z.union([
                                    z.object({
                                        buildingType: z
                                            .string({
                                            required_error: "Bitte gib den Ort des Anschlusses an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib den Ort des Anschlusses an."),
                                        buildingFloor: z
                                            .string({
                                            required_error: "Bitte gib dein Stockwerk/deine Etage an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib dein Stockwerk/deine Etage an."),
                                        buildingAlignment: z
                                            .string({
                                            required_error: "Bitte gib die Ausrichtung des Gebäudes an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib dein Stockwerk/deine Etage an."),
                                    }),
                                    z.object({
                                        buildingType: z.literal(BuildingType.Detached, {
                                            required_error: "Bitte gib den Ort des Anschlusses an.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                component: "o2.providerChangeSection",
                                defaultValues: {
                                    providerChange: true,
                                    formerProvider: "",
                                    numberPorting: false,
                                    currentProviderNumberPrefix: "",
                                    currentProviderNumberExtension: "",
                                },
                                schema: z.union([
                                    z.object({
                                        providerChange: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        formerProvider: z
                                            .string({
                                            required_error: "Bitte gib deinen bisherigen Provider an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib deinen bisherigen Provider an."),
                                        numberPorting: z.boolean({
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        currentProviderNumberPrefix: z
                                            .string({
                                            required_error: "Bitte gib eine Vorwahl ein.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib eine Vorwahl ein.")
                                            .regex(/^0\d+$/, "Bitte gib eine gültige Vorwahl ein.")
                                            .regex(/^0[2-9]\d+$/, "Bitte gib eine Festnetznummer ein."),
                                        currentProviderNumberExtension: z
                                            .string({
                                            required_error: "Bitte gib deine Rufnummer ein.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib deine Rufnummer ein.")
                                            .regex(/^[0-9]+$/, "Bitte gib deine Rufnummer ein."),
                                    }),
                                    z.object({
                                        providerChange: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                component: "phoneSubscriberSection",
                                // only show for o2 dsl
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                defaultValues: {
                                    canDecideProviderChange: false,
                                    solePhoneSubscriber: true,
                                    phoneSubscriberFirstName: "",
                                    phoneSubscriberLastName: "",
                                },
                                schema: z.union([
                                    z.object({
                                        solePhoneSubscriber: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        canDecideProviderChange: z.literal(false),
                                    }),
                                    z.object({
                                        solePhoneSubscriber: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        canDecideProviderChange: z
                                            .boolean()
                                            .refine((value) => value, {
                                            message: "Bitte bestätige, dass du über den Telekommunikationsanschluss entscheiden darfst.",
                                        }),
                                        phoneSubscriberFirstName: z
                                            .string({
                                            required_error: "Bitte gib einen Vornamen an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib einen Vornamen ein."),
                                        phoneSubscriberLastName: z
                                            .string({
                                            required_error: "Bitte gib einen Nachnamen an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib einen Nachnamen ein."),
                                    }),
                                ]),
                            },
                            // o2 cable
                            {
                                component: "o2.activationDateSection",
                                hide: (options) => options.connectionType !== ConnectionType.cable,
                                defaultValues: {
                                    activationDate: "",
                                },
                                options: {
                                    selectableStartInDays: 20,
                                    selectableEndInDays: 90,
                                },
                                schema: z.object({
                                    activationDate: z
                                        .string({
                                        required_error: "Bitte gib ein Aktivierungsdatum an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib ein Aktivierungsdatum an.")
                                        .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                        .refine((value) => {
                                        try {
                                            const date = parse(value, "dd.MM.yyyy", new Date());
                                            return isWithinInterval(date, {
                                                start: startOfDay(addDays(new Date(), 20)),
                                                end: startOfDay(addDays(new Date(), 90)),
                                            });
                                        }
                                        catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error(e);
                                            return false;
                                        }
                                    }, {
                                        message: "Bitte gib ein Datum zwischen 20 und 90 Tagen in der Zukunft an.",
                                    }),
                                }),
                            },
                            // o2 dsl
                            {
                                component: "o2.activationDateSection",
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                defaultValues: {
                                    activationDate: "",
                                },
                                options: {
                                    selectableStartInDays: 19,
                                    selectableEndInDays: 180,
                                },
                                schema: z.object({
                                    activationDate: z
                                        .string({
                                        required_error: "Bitte gib ein Aktivierungsdatum an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib ein Aktivierungsdatum an.")
                                        .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                        .refine((value) => {
                                        try {
                                            const date = parse(value, "dd.MM.yyyy", new Date());
                                            return isWithinInterval(date, {
                                                start: startOfDay(addDays(new Date(), 19)),
                                                end: startOfDay(addDays(new Date(), 180)),
                                            });
                                        }
                                        catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error(e);
                                            return false;
                                        }
                                    }, {
                                        message: "Bitte gib ein Datum zwischen 19 und 180 Tagen in der Zukunft an.",
                                    }),
                                }),
                            },
                            {
                                component: "o2.installationTimeWindowSection",
                                defaultValues: {
                                    installationTimeWindow: InstallationTimeWindow.NotSpecified,
                                },
                                // only show for o2 dsl
                                hide: (options) => options.connectionType !== ConnectionType.dsl,
                                options: {
                                    installationTimeWindowOptions: [
                                        {
                                            key: InstallationTimeWindow.NotSpecified,
                                            label: "Nein",
                                        },
                                        {
                                            key: InstallationTimeWindow.EightToTwelve,
                                            label: getInstallationTimeWindowLabel(InstallationTimeWindow.EightToTwelve),
                                        },
                                        {
                                            key: InstallationTimeWindow.TwelveToFour,
                                            label: getInstallationTimeWindowLabel(InstallationTimeWindow.TwelveToFour),
                                        },
                                    ],
                                },
                                schema: z.object({
                                    installationTimeWindow: z.nativeEnum(InstallationTimeWindow),
                                }),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contactAndPayment",
                sections: [
                    {
                        title: "Kontakt",
                        sections: [
                            {
                                component: "contactAddressSection",
                                defaultValues: {
                                    contactAddressSalutation: "Frau",
                                },
                                schema: z.object({
                                    contactAddressSalutation: z.enum(["Herr", "Frau"], {
                                        required_error: "Bitte wähle eine Anrede aus.",
                                    }),
                                    contactAddressFirstName: z
                                        .string({
                                        required_error: "Bitte gib deinen Vornamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Vornamen ein."),
                                    contactAddressLastName: z
                                        .string({
                                        required_error: "Bitte gib deinen Nachnamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Nachnamen ein."),
                                    contactAddressZipCode: z
                                        .string({
                                        required_error: "Bitte gib deine Postleitzahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Postleitzahl ein.")
                                        .length(5, "Deine Postleitzahl muss aus 5 Ziffern bestehen.")
                                        .regex(/^[0-9]+$/, "Deine Postleitzahl muss aus Zahlen bestehen."),
                                    contactAddressPlace: z
                                        .string({
                                        required_error: "Bitte wähle deinen Wohnort aus.",
                                    })
                                        .trim()
                                        .nonempty("Bitte wähle deinen Wohnort aus."),
                                    contactAddressStreet: z
                                        .string({
                                        required_error: "Bitte gib deinen Straßennamen ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Straßennamen ein.")
                                        .max(50, "Dein Straßenname darf maximal 50 Zeichen lang sein."),
                                    contactAddressStreetNumber: z
                                        .string({
                                        required_error: "Bitte gib deine Hausnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Hausnummer ein."),
                                    contactAddressBirthDay: z
                                        .string({
                                        required_error: "Bitte gib dein Geburtsdatum ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib dein Geburtsdatum ein.")
                                        .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                        .refine((value) => {
                                        const startDate = parse(value, "dd.MM.yyyy", new Date());
                                        return isValid(startDate);
                                    }, {
                                        message: "Bitte gib ein gültiges Datum ein.",
                                    })
                                        .refine((value) => {
                                        try {
                                            const startDate = parse(value, "dd.MM.yyyy", new Date());
                                            if (!isValid(startDate) || isFuture(startDate))
                                                return false;
                                            const age = formatISODuration(intervalToDuration({
                                                start: parse(value, "dd.MM.yyyy", new Date()),
                                                end: new Date(),
                                            })).match(/^P(\d+)Y/)?.[1];
                                            return Number(age) >= 18;
                                        }
                                        catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error(e);
                                            return false;
                                        }
                                    }, {
                                        message: "Das Mindestalter zum Abschluss des Vertrages beträgt 18 Jahre.",
                                    }),
                                    contactAddressEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressConfirmEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse erneut ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse erneut ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressPhoneNumberPrefix: z
                                        .string({
                                        required_error: "Bitte gib eine Vorwahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Vorwahl ein.")
                                        .regex(/^0[1-9][0-9]{1,4}$/, "Bitte gib eine gültige Vorwahl ein."),
                                    contactAddressPhoneNumberExtension: z
                                        .string({
                                        required_error: "Bitte gib eine Rufnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Rufnummer ein.")
                                        .max(8, "Bitte gib maximal 8 Zeichen ein.")
                                        .regex(/^[0-9]{3,}$/, "Bitte gib eine Rufnummer ein."),
                                }),
                            },
                            {
                                component: "shippingAddressSection",
                                defaultValues: {
                                    differingShippingAddress: false,
                                    shippingAddressSalutation: "Frau",
                                    shippingAddressFirstName: "",
                                    shippingAddressLastName: "",
                                    shippingAddressZipCode: "",
                                    shippingAddressPlace: "",
                                    shippingAddressStreet: "",
                                    shippingAddressStreetNumber: "",
                                },
                                // only show for o2 cable
                                hide: (options) => options.connectionType !== ConnectionType.cable,
                                schema: shippingAddressSection(),
                                options: {
                                    enableAddressSuggestions: true,
                                },
                            },
                        ],
                    },
                    {
                        title: "Bezahlung",
                        sections: [
                            {
                                component: "bankAccountSection",
                                defaultValues: {
                                    sepaAccepted: false,
                                },
                                options: {
                                    label: "Ich ermächtige Telefonica Germany, Zahlungen von meinem Konto per SEPA Lastschrift einzuziehen.",
                                },
                                schema: z.object({
                                    sepaAccepted: z.boolean().refine((value) => value, {
                                        message: "Bitte stimme dem SEPA-Mandat zu",
                                    }),
                                    iban: z
                                        .string({
                                        required_error: "Bitte gib deine IBAN ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine IBAN ein."),
                                }),
                            },
                            {
                                component: "promotionCodeSection",
                                hide: (options) => Boolean(options.onlineOnly),
                                defaultValues: {
                                    promotionCode: "",
                                },
                                schema: z.object({
                                    promotionCode: z.string().trim().optional(),
                                }),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contractSummary",
                sections: [
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "o2.itemisedBillingSection",
                                defaultValues: {
                                    itemisedBilling: "no",
                                },
                                schema: z.object({
                                    itemisedBilling: z.nativeEnum(ItemisedBilling, {
                                        required_error: "Bitte wähle deine Präferenz für den Einzelverbindungsnachweis aus.",
                                    }),
                                }),
                            },
                            {
                                component: "o2.personalCustomerCodeSection",
                                defaultValues: {
                                    personalCustomerCode: "",
                                },
                                schema: z.object({
                                    personalCustomerCode: z
                                        .string({
                                        required_error: "Bitte gib deine Persönliche Kundenkennzahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Persönliche Kundenkennzahl ein.")
                                        .regex(/^[0-9]+$/, "Deine Kundenkennzahl muss aus Zahlen bestehen.")
                                        .length(4, "Bitte gib eine 4-stellige Kundenkennzahl ein."),
                                }),
                            },
                            {
                                component: "o2.contractSummarySection",
                                defaultValues: {
                                    eeccApprovalToSendSummary: false,
                                },
                                schema: z.object({
                                    eeccApprovalToSendSummary: z
                                        .boolean({
                                        required_error: "Bitte bestätige, dass dir die Vertragsdokumente und rechtliche Pflichtinformationen von o2 (Telefónica Germany GmbH & Co. OHG) per Mail zugesandt werden können.",
                                    })
                                        .refine((value) => value, {
                                        message: "Bitte bestätige, dass dir die Vertragsdokumente und rechtliche Pflichtinformationen von o2 (Telefónica Germany GmbH & Co. OHG) per Mail zugesandt werden können.",
                                    }),
                                }),
                            },
                        ],
                    },
                ],
            },
            {
                type: "orderSummary",
                legalNote: (pibUrl, openAGBModal, openPrivacyModal) => (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "https://content.ekontor24.net/mediamarkt/pdf/AGB_Festnetz_2020-03.pdf", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen der o2" }), ", sowie die", " ", _jsx("a", { href: "https://content.ekontor24.net/mediamarkt/pdf/datenschutzmerkblatt-telefonica.pdf", target: "_blank", rel: "noreferrer", children: "Informationen zum Datenschutz" }), " ", "zur Kenntnis genommen habe und mit ihnen einverstanden bin."] }), _jsxs("p", { children: ["Weitere Informationen finde ich hier im", " ", _jsx("a", { href: pibUrl, target: "_blank", rel: "noreferrer", children: "Produktinformationsblatt" }), "."] }), _jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "/agb/", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen" }), " ", "der S-KON eKontor 24 GmbH sowie die", " ", _jsx("a", { href: "/datenschutz/", target: "_blank", rel: "noreferrer", children: "Informationen zum Datenschutz" }), " ", "der S-KON eKontor 24 GmbH zur Kenntnis genommen habe und mit ihnen einverstanden bin."] })] })),
                submitLabel: "Kostenpflichtig bestellen",
                sections: [
                    {
                        title: "Anschluss",
                        sections: [
                            {
                                component: "o2.connectionOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Kontakt & Bezahlung",
                        sections: [
                            {
                                component: "o2.contactOverviewSection",
                            },
                            {
                                component: "o2.paymentOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "o2.contractOverviewSection",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        key: "1und1",
        steps: [
            {
                type: "connection",
                sections: [
                    {
                        title: "Dein Anschluss",
                        sections: [
                            {
                                component: "connectionAddressSection",
                            },
                            {
                                component: "1und1.providerChangeSection",
                                defaultValues: {
                                    formerProvider: "",
                                },
                                schema: z.union([
                                    z.object({
                                        providerChange: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        formerProvider: z
                                            .string({
                                            required_error: "Bitte gib deinen bisherigen Provider an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib deinen bisherigen Provider an."),
                                    }),
                                    z.object({
                                        providerChange: z
                                            .boolean({
                                            required_error: "Bitte triff eine Auswahl.",
                                        })
                                            .optional()
                                            .refine((value) => value === false, {
                                            message: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                component: "1und1.contractTerminationSection",
                                hide: (options, state) => !state?.providerChange,
                                defaultValues: {
                                    digitalLetterOfIntent: false,
                                    contractTerminationDate: "",
                                },
                                schema: z.union([
                                    z.object({
                                        contractTerminated: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        digitalLetterOfIntent: z.literal(false),
                                        contractTerminationDate: z
                                            .string({
                                            required_error: "Bitte gib ein Vertragsende an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib ein Vertragsende an.")
                                            .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein."),
                                    }),
                                    z.object({
                                        contractTerminated: z
                                            .boolean({
                                            required_error: "Bitte triff eine Auswahl.",
                                        })
                                            .optional()
                                            .refine((value) => value === false, {
                                            message: "Bitte triff eine Auswahl.",
                                        }),
                                        digitalLetterOfIntent: z.coerce
                                            .boolean()
                                            .refine((bool) => bool == true, {
                                            message: "Bitte bestätige die Vollmacht zur Vertragskündigung.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                component: "1und1.connectionActivationSection",
                                hide: (options, state) => {
                                    if (state?.providerChange === undefined)
                                        return true;
                                    if (state?.providerChange) {
                                        const contractTerminationDate = state?.contractTerminationDate
                                            ? parse(state.contractTerminationDate, "dd.MM.yyyy", new Date())
                                            : undefined;
                                        return contractTerminationDate
                                            ? contractTerminationDate <=
                                                addDays(startOfDay(new Date()), 7)
                                            : false;
                                    }
                                    return false;
                                },
                                defaultValues: {
                                    activationDateSelection: false,
                                    activationDate: "",
                                },
                                options: {
                                    selectableStartInDays: 28,
                                    selectableEndInDays: 118,
                                },
                                schema: z.union([
                                    z.object({
                                        activationDateSelection: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        activationDate: z
                                            .string({
                                            required_error: "Bitte gib ein Aktivierungsdatum an.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib ein Aktivierungsdatum an.")
                                            .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                            .refine((value) => {
                                            // FIXME: why is this not triggered?
                                            try {
                                                const date = parse(value, "dd.MM.yyyy", new Date());
                                                return isWithinInterval(date, {
                                                    start: startOfDay(addDays(new Date(), 20)),
                                                    end: startOfDay(addDays(new Date(), 118)),
                                                });
                                            }
                                            catch (e) {
                                                // eslint-disable-next-line no-console
                                                console.error(e);
                                                return false;
                                            }
                                        }, {
                                            message: "Bitte gib ein Datum zwischen 20 und 118 Tagen in der Zukunft an.",
                                        }),
                                    }),
                                    z.object({
                                        activationDateSelection: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                            {
                                component: "1und1.numberPortingSection",
                                hide: (options, state) => {
                                    if (!state?.providerChange)
                                        return true;
                                    const contractTerminationDate = state?.contractTerminationDate
                                        ? parse(state.contractTerminationDate, "dd.MM.yyyy", new Date())
                                        : undefined;
                                    return contractTerminationDate
                                        ? contractTerminationDate <=
                                            addDays(startOfDay(new Date()), 7)
                                        : false;
                                },
                                defaultValues: {
                                    numberPorting: false,
                                    numberPortingIntent: false,
                                    currentProviderNumberPrefix: "",
                                    currentProviderNumberExtension: "",
                                    currentProviderNumberExtension01: "",
                                    currentProviderNumberExtension02: "",
                                },
                                schema: z.union([
                                    z.object({
                                        numberPorting: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        numberPortingIntent: z
                                            .boolean({
                                            required_error: "Bitte triff eine Auswahl.",
                                        })
                                            .refine((value) => value, {
                                            message: "Bitte bestätige deine Beauftragung.",
                                        }),
                                        currentProviderNumberPrefix: z
                                            .string({
                                            required_error: "Bitte gib eine Vorwahl ein.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib eine Vorwahl ein.")
                                            .max(6, "Bitte maximal 6 Zeichen eingeben")
                                            .regex(/^0\d+$/, "Bitte gib eine gültige Vorwahl ein.")
                                            .regex(/^0[2-9]\d+$/, "Bitte gib eine Festnetznummer ein."),
                                        currentProviderNumberExtension: z
                                            .string({
                                            required_error: "Bitte gib deine Rufnummer ein.",
                                        })
                                            .trim()
                                            .nonempty("Bitte gib deine Rufnummer ein.")
                                            .max(8, "Bitte maximal 8 Zeichen eingeben")
                                            .regex(/^[0-9]{3,}$/, "Bitte gib deine Rufnummer ein."),
                                    }),
                                    z.object({
                                        numberPorting: z.literal(true, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                        currentProviderNumberExtension01: z
                                            .string()
                                            .trim()
                                            .nonempty("Bitte gib deine Rufnummer ein.")
                                            .regex(/^[0-9]+$/, "Bitte gib deine Rufnummer ein.")
                                            .optional(),
                                        currentProviderNumberExtension02: z
                                            .string()
                                            .trim()
                                            .nonempty("Bitte gib deine Rufnummer ein.")
                                            .regex(/^[0-9]+$/, "Bitte gib deine Rufnummer ein.")
                                            .optional(),
                                    }),
                                    z.object({
                                        numberPorting: z.literal(false, {
                                            required_error: "Bitte triff eine Auswahl.",
                                        }),
                                    }),
                                ]),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contactAndPayment",
                sections: [
                    {
                        title: "Kontakt & Bezahlung",
                        sections: [
                            {
                                component: "contactAddressSection",
                                defaultValues: {
                                    contactAddressSalutation: "Frau",
                                },
                                schema: z.object({
                                    contactAddressSalutation: z.enum(["Herr", "Frau"], {
                                        required_error: "Bitte wähle eine Anrede aus.",
                                    }),
                                    contactAddressFirstName: z
                                        .string({
                                        required_error: "Bitte gib deinen Vornamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Vornamen ein.")
                                        .regex(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, "Gib einen gültigen Vornamen ein."),
                                    contactAddressLastName: z
                                        .string({
                                        required_error: "Bitte gib deinen Nachnamen an.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Nachnamen ein.")
                                        .regex(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, "Gib einen gültigen Nachnamen ein."),
                                    contactAddressZipCode: z
                                        .string({
                                        required_error: "Bitte gib deine Postleitzahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Postleitzahl ein.")
                                        .length(5, "Deine Postleitzahl muss aus 5 Ziffern bestehen.")
                                        .regex(/^[0-9]+$/, "Deine Postleitzahl muss aus Zahlen bestehen."),
                                    contactAddressPlace: z
                                        .string({
                                        required_error: "Bitte wähle deinen Wohnort aus.",
                                    })
                                        .trim()
                                        .nonempty("Bitte wähle deinen Wohnort aus."),
                                    contactAddressStreet: z
                                        .string({
                                        required_error: "Bitte gib deinen Straßennamen ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deinen Straßennamen ein.")
                                        .max(50, "Dein Straßenname darf maximal 50 Zeichen lang sein."),
                                    contactAddressStreetNumber: z
                                        .string({
                                        required_error: "Bitte gib deine Hausnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine Hausnummer ein."),
                                    contactAddressBirthDay: z
                                        .string({
                                        required_error: "Bitte gib dein Geburtsdatum ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib dein Geburtsdatum ein.")
                                        .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                        .refine((value) => {
                                        const startDate = parse(value, "dd.MM.yyyy", new Date());
                                        return isValid(startDate);
                                    }, {
                                        message: "Bitte gib ein gültiges Datum ein.",
                                    })
                                        .refine((value) => {
                                        try {
                                            const startDate = parse(value, "dd.MM.yyyy", new Date());
                                            if (!isValid(startDate) || isFuture(startDate))
                                                return false;
                                            const age = formatISODuration(intervalToDuration({
                                                start: parse(value, "dd.MM.yyyy", new Date()),
                                                end: new Date(),
                                            })).match(/^P(\d+)Y/)?.[1];
                                            return Number(age) >= 18;
                                        }
                                        catch (e) {
                                            // eslint-disable-next-line no-console
                                            console.error(e);
                                            return false;
                                        }
                                    }, {
                                        message: "Das Mindestalter zum Abschluss des Vertrages beträgt 18 Jahre.",
                                    }),
                                    contactAddressEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressConfirmEmail: z
                                        .string({
                                        required_error: "Bitte gib deine E-Mail Adresse erneut ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine E-Mail Adresse erneut ein.")
                                        .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                                    contactAddressPhoneNumberPrefix: z
                                        .string({
                                        required_error: "Bitte gib eine Vorwahl ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Vorwahl ein.")
                                        .regex(/^0[1-9][0-9]{1,4}$/, "Bitte gib eine gültige Vorwahl ein."),
                                    contactAddressPhoneNumberExtension: z
                                        .string({
                                        required_error: "Bitte gib eine Rufnummer ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib eine Rufnummer ein.")
                                        .max(8, "Bitte gib maximal 8 Zeichen ein.")
                                        .regex(/^[0-9]{3,}$/, "Bitte gib eine Rufnummer ein."),
                                }),
                            },
                            {
                                component: "shippingAddressSection",
                                defaultValues: {
                                    differingShippingAddress: false,
                                    shippingAddressSalutation: "Frau",
                                    shippingAddressFirstName: "",
                                    shippingAddressLastName: "",
                                    shippingAddressZipCode: "",
                                    shippingAddressPlace: "",
                                    shippingAddressStreet: "",
                                    shippingAddressStreetNumber: "",
                                },
                                schema: shippingAddressSection(),
                                options: {
                                    enableAddressSuggestions: true,
                                },
                            },
                            {
                                component: "bankAccountSection",
                                defaultValues: {
                                    sepaAccepted: false,
                                },
                                options: {
                                    label: "Ja, ich ermächtige die 1&1 Telecom GmbH (Gläubiger ID: DE77ZZZ00000 69719) die Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der auf mein Konto gezogenen Lastschriften einzulösen.",
                                    content: (_jsx(CheckoutSectionText, { children: "Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen." })),
                                },
                                schema: z.object({
                                    sepaAccepted: z.boolean().refine((value) => value, {
                                        message: "Bitte stimme dem SEPA-Mandat zu",
                                    }),
                                    iban: z
                                        .string({
                                        required_error: "Bitte gib deine IBAN ein.",
                                    })
                                        .trim()
                                        .nonempty("Bitte gib deine IBAN ein."),
                                }),
                            },
                            {
                                component: "promotionCodeSection",
                                hide: (options) => Boolean(options.onlineOnly),
                                defaultValues: {
                                    promotionCode: "",
                                },
                                schema: z.object({
                                    promotionCode: z.string().trim().optional(),
                                }),
                            },
                        ],
                    },
                ],
            },
            {
                type: "contractSummary",
                sections: [
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "1und1.contractSummarySection",
                                defaultValues: {
                                    eeccDocumentsDownloaded: false,
                                    eeccDocumentMaxRetriesReached: false,
                                },
                                schema: z.union([
                                    z.object({
                                        eeccDocumentsDownloaded: z
                                            .boolean()
                                            .refine((value) => value, {
                                            message: "Die Bestellung kann erst abgeschlossen werden, wenn Sie die Vertragsdokumente heruntergeladen haben.",
                                        }),
                                        eeccDocumentMaxRetriesReached: z
                                            .literal(false)
                                            .optional(),
                                    }),
                                    z.object({
                                        eeccDocumentsDownloaded: z
                                            .literal(false)
                                            .optional(),
                                        eeccDocumentMaxRetriesReached: z.literal(true),
                                    }),
                                ]),
                            },
                        ],
                    },
                ],
            },
            {
                type: "orderSummary",
                legalNote: (pibUrl, openAGBModal, openPrivacyModal) => (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "https://content.ekontor24.net/mediamarkt/pdf/agb_1und1.pdf", target: "_blank", rel: "noreferrer", children: "1&1 AGB" }), ", die", " ", _jsx("a", { href: "https://content.1und1.de/pdfs/1und1_Preisliste_DslPakete.pdf", target: "_blank", rel: "noreferrer", children: "1&1 Preisliste" }), ",", " ", _jsx("a", { href: "https://content.1und1.de/pdfs/1und1_Leistungsbeschreibung.pdf", target: "_blank", rel: "noreferrer", children: "1&1 Leistungsbeschreibung" }), ", sowie die", " ", _jsx("a", { href: "https://content.1und1.de/pdfs/1und1-Datenschutzhinweis-zum-Vertrag.pdf", target: "_blank", rel: "noreferrer", children: "Datenschutzhinweise" }), " ", "zur Kenntnis genommen habe und mit ihnen einverstanden bin."] }), _jsxs("p", { children: ["Auf das", " ", _jsx("a", { href: "https://content.1und1.de/pdfs/1und1-Widerrufsbelehrung.pdf", target: "_blank", rel: "noreferrer", children: "1&1 Widerrufsrecht" }), " ", "wurde ich hingewiesen."] }), _jsxs("p", { children: ["Weitere Informationen finde ich hier im", " ", _jsx("a", { href: pibUrl, target: "_blank", rel: "noreferrer", children: "Produktinformationsblatt" }), "."] }), _jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "/agb/", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen" }), " ", "der S-KON eKontor 24 GmbH sowie die", " ", _jsx("a", { href: "/datenschutz/", target: "_blank", rel: "noreferrer", children: "Informationen zum Datenschutz" }), " ", "der S-KON eKontor 24 GmbH zur Kenntnis genommen habe und mit ihnen einverstanden bin."] })] })),
                submitLabel: "Kostenpflichtig bestellen",
                sections: [
                    {
                        title: "Anschluss",
                        sections: [
                            {
                                component: "1und1.connectionOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Kontakt & Bezahlung",
                        sections: [
                            {
                                component: "1und1.contactOverviewSection",
                            },
                            {
                                component: "1und1.paymentOverviewSection",
                            },
                        ],
                    },
                    {
                        title: "Vertragszusammenfassung",
                        sections: [
                            {
                                component: "1und1.contractOverviewSection",
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
