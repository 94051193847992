import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { CheckoutSection, RadioList } from "../components";
import { InputComboBox, InputText } from "@mm/ui";
import { useCheckoutState } from "../context/CheckoutContext";
import { useCheckout } from "../hooks/api";
import { useField, useValidation, ValidateOnEvent, } from "../hooks";
import styled from "styled-components";
import { CheckoutType } from "@/types";
import { transformDateString } from "@/utils/transformDateString";
import { useAddressSuggestions } from "@/features/Internet";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
// TODO: rename wrappers
const FullWidthWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const OneThirdWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const HalfWidthWrapper = styled.div `
  flex: 0 0 50%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
export const ContactAddressSection = ({ options, schema }) => {
    const { state, dispatch } = useCheckoutState();
    const { isFetching, isLoading } = useCheckout(state);
    const { enableAddressSuggestions, title } = options ?? {
        enableAddressSuggestions: false,
    };
    const { errors: salutationErrors, fieldState: salutation, setFieldState: setSalutation, trigger: triggerSalutation, } = useField("contactAddressSalutation", schema, "Frau");
    const { errors: firstNameErrors, fieldState: firstName, setFieldState: setFirstName, trigger: triggerFirstName, } = useField("contactAddressFirstName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: lastNameErrors, fieldState: lastName, setFieldState: setLastName, trigger: triggerLastName, } = useField("contactAddressLastName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: zipCodeErrors, fieldState: zipCode, setFieldState: setZipCode, trigger: triggerZipCode, } = useField("contactAddressZipCode", schema, enableAddressSuggestions ? "" : state?.contactAddressZipCode ?? "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: placeErrors, fieldState: place, setFieldState: setPlace, } = useField("contactAddressPlace", schema, enableAddressSuggestions ? "" : state?.contactAddressPlace ?? "", {
        validateOn: ValidateOnEvent.Change,
        forceContextState: Boolean(enableAddressSuggestions),
    });
    const { errors: streetErrors, fieldState: street, setFieldState: setStreet, trigger: triggerStreet, } = useField("contactAddressStreet", schema, enableAddressSuggestions ? "" : state?.contactAddressStreet ?? "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: streetNumberErrors, fieldState: streetNumber, setFieldState: setStreetNumber, trigger: triggerStreetNumber, } = useField("contactAddressStreetNumber", schema, enableAddressSuggestions ? "" : state?.contactAddressStreetNumber ?? "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: birthDayErrors, fieldState: birthDay, setFieldState: setBirthDay, trigger: triggerBirthDay, } = useField("contactAddressBirthDay", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: emailErrors, fieldState: email, setFieldState: setEmail, trigger: triggerEmail, } = useField("contactAddressEmail", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: confirmEmailErrors, fieldState: confirmEmail, setFieldState: setConfirmEmail, trigger: triggerConfirmEmail, } = useField("contactAddressConfirmEmail", schema, "", {
        validateOn: ValidateOnEvent.Manual,
        mutate: true,
    });
    const { errors: prefixErrors, fieldState: prefix, setFieldState: setPrefix, trigger: triggerPrefix, } = useField("contactAddressPhoneNumberPrefix", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: extensionErrors, fieldState: extension, setFieldState: setExtension, trigger: triggerExtension, } = useField("contactAddressPhoneNumberExtension", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { addError, clearErrors, } = useValidation();
    const { data: addressSuggestions, clear: clearAddressSuggestions, refetch: refetchAddressSuggestions, success: klicktelSuccess, } = useAddressSuggestions(enableAddressSuggestions
        ? {
            zip: zipCode,
            city: place,
            street,
            streetNumber,
        }
        : {}, {
        enabled: false,
    });
    useEffect(() => {
        if (enableAddressSuggestions) {
            clearAddressSuggestions();
        }
    }, []);
    useEffect(() => {
        if (email !== confirmEmail || confirmEmail !== email) {
            addError(["contactAddressEmail", "contactAddressConfirmEmail"], "Die E-Mail Adressen müssen identisch sein.", schema);
        }
        else {
            clearErrors(["contactAddressEmail", "contactAddressConfirmEmail"]);
        }
    }, [email, confirmEmail]);
    useEffect(() => {
        if (addressSuggestions?.cities?.length === 1) {
            setPlace(addressSuggestions.cities[0]);
            refetchAddressSuggestions();
        }
    }, [addressSuggestions?.cities]);
    const disableAddressFields = state?.checkoutType === CheckoutType.internet;
    return (_jsxs(CheckoutSection, { children: [_jsx(RadioList
            // TODO: refactor RadioList keys to enum
            , { 
                // TODO: refactor RadioList keys to enum
                checked: salutation === "Frau"
                    ? "contactAddressSalutation--frau"
                    : "contactAddressSalutation--herr", id: "contactAddressSalutation", items: [
                    {
                        key: "contactAddressSalutation--frau",
                        label: "Frau",
                        qaName: "contact_salutation_female",
                    },
                    {
                        key: "contactAddressSalutation--herr",
                        label: "Herr",
                        qaName: "contact_salutation_male",
                    },
                ], onChange: (key) => {
                    setSalutation(key === "contactAddressSalutation--frau" ? "Frau" : "Herr");
                    triggerSalutation();
                }, disabled: isLoading || isFetching, error: salutationErrors?.[0]?.message }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "contactAddressFirstName", label: "Vorname*", value: firstName, message: firstNameErrors?.[0]?.message, layout: firstNameErrors && "error", onChange: (value) => {
                                setFirstName(value);
                            }, onBlur: () => triggerFirstName(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_firstname" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "contactAddressLastName", label: "Nachname*", value: lastName, message: lastNameErrors?.[0]?.message, layout: lastNameErrors && "error", onChange: (value) => {
                                setLastName(value);
                            }, onBlur: () => triggerLastName(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_lastname" }) })] }), _jsxs(Row, { children: [_jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "contactAddressZipCode", label: "PLZ*", value: zipCode, message: zipCodeErrors?.[0]?.message, layout: zipCodeErrors && "error", onChange: (value) => {
                                setZipCode(value);
                                if (enableAddressSuggestions) {
                                    clearAddressSuggestions();
                                    clearErrors([
                                        "contactAddressPlace",
                                        "contactAddressStreet",
                                        "contactAddressStreetNumber",
                                    ]);
                                    setPlace("");
                                    setStreet("");
                                    setStreetNumber("");
                                }
                            }, onBlur: () => {
                                triggerZipCode().then((success) => {
                                    if (enableAddressSuggestions && success) {
                                        refetchAddressSuggestions();
                                    }
                                });
                            }, disabled: disableAddressFields || isLoading || isFetching, autoComplete: "none", qaName: "contact_zip" }) }), _jsx(FullWidthWrapper, { children: enableAddressSuggestions && klicktelSuccess ? (_jsx(InputComboBox, { id: "contactAddressPlace", label: "Ort*", value: place, message: placeErrors?.[0]?.message, layout: placeErrors && "error", onChange: (value) => {
                                setPlace(value);
                                clearAddressSuggestions();
                                clearErrors([
                                    "contactAddressStreet",
                                    "contactAddressStreetNumber",
                                ]);
                                setStreet("");
                                setStreetNumber("");
                            }, onBlur: () => {
                                refetchAddressSuggestions();
                            }, onClick: () => {
                                refetchAddressSuggestions();
                            }, disabled: disableAddressFields || isLoading || isFetching || !zipCode, items: addressSuggestions?.cities ?? [], autoComplete: "none", qaName: "contact_city" })) : (_jsx(InputText, { id: "contactAddressPlace", label: "Ort*", value: place, message: placeErrors?.[0]?.message, layout: placeErrors && "error", onChange: (value) => {
                                setPlace(value);
                            }, disabled: disableAddressFields ||
                                isLoading ||
                                isFetching ||
                                (enableAddressSuggestions && !zipCode), autoComplete: "none", qaName: "contact_city" })) })] }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: enableAddressSuggestions && klicktelSuccess ? (_jsx(InputComboBox, { id: "contactAddressStreet", label: "Stra\u00DFe*", value: street, message: streetErrors?.[0]?.message, layout: streetErrors && "error", onChange: (value) => {
                                setStreet(value);
                                clearErrors(["contactAddressStreetNumber"]);
                                setStreetNumber("");
                            }, onBlur: () => triggerStreet(), disabled: disableAddressFields ||
                                isLoading ||
                                isFetching ||
                                !zipCode ||
                                !place, items: addressSuggestions?.streets ?? [], autoComplete: "none", qaName: "contact_street" })) : (_jsx(InputText, { id: "contactAddressStreet", label: "Stra\u00DFe*", value: street, message: streetErrors?.[0]?.message, layout: streetErrors && "error", onChange: (value) => {
                                setStreet(value);
                            }, onBlur: () => triggerStreet(), disabled: disableAddressFields ||
                                isLoading ||
                                isFetching ||
                                (enableAddressSuggestions && (!zipCode || !place)), autoComplete: "none", qaName: "contact_street" })) }), _jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "contactAddressStreetNumber", label: "Nummer*", value: streetNumber, message: streetNumberErrors?.[0]?.message, layout: streetNumberErrors && "error", onChange: (value) => {
                                setStreetNumber(value);
                            }, onBlur: () => triggerStreetNumber(), disabled: disableAddressFields ||
                                isLoading ||
                                isFetching ||
                                (enableAddressSuggestions && (!zipCode || !place || !street)), autoComplete: "none", qaName: "contact_streetnumber" }) })] }), _jsx(Row, { children: _jsx(HalfWidthWrapper, { children: _jsx(InputText, { id: "contactAddressBirthDay", label: "Geburtsdatum (TT.MM.JJJJ)*", value: birthDay, message: birthDayErrors?.[0]?.message, layout: birthDayErrors && "error", onKeyDown: (event) => {
                            const { selectionStart, selectionEnd } = event.target;
                            if (event.code && event.code === "Backspace") {
                                event.preventDefault();
                                if (birthDay && birthDay.length > 0) {
                                    if (selectionStart !== selectionEnd) {
                                        setBirthDay(birthDay.slice(0, selectionStart ?? undefined) +
                                            birthDay.slice(selectionEnd ?? undefined));
                                        return;
                                    }
                                    setBirthDay(birthDay.slice(0, selectionStart ? selectionStart - 1 : undefined) + birthDay.slice(selectionStart ?? undefined));
                                }
                                return;
                            }
                        }, onChange: (value) => {
                            setBirthDay(transformDateString(value));
                        }, onBlur: () => triggerBirthDay(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_dob" }) }) }), _jsxs(Row, { children: [_jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "contactAddressPhoneNumberPrefix", label: "Vorwahl*", value: prefix, message: prefixErrors?.[0]?.message, layout: prefixErrors && "error", onChange: (value) => {
                                setPrefix(value);
                            }, onBlur: () => triggerPrefix(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_tel-prefix-number" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "contactAddressPhoneNumberExtension", label: "Telefonnummer*", value: extension, message: extensionErrors?.[0]?.message, layout: extensionErrors && "error", onChange: (value) => {
                                setExtension(value);
                            }, onBlur: () => triggerExtension(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_tel-number" }) })] }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "contactAddressEmail", label: "E-Mail*", value: email, message: emailErrors?.[0]?.message, layout: emailErrors && "error", onChange: (value) => {
                                setEmail(value);
                            }, onBlur: () => triggerEmail(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_email" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "contactAddressConfirmEmail", label: "E-Mail wiederholen*", value: confirmEmail, message: confirmEmailErrors?.[0]?.message, layout: confirmEmailErrors && "error", onChange: (value) => {
                                setConfirmEmail(value);
                            }, onBlur: () => triggerConfirmEmail(), disabled: isLoading || isFetching, autoComplete: "none", qaName: "contact_emailRepeat" }) })] })] }));
};
